import { platformWorkerPromise } from 'thunderbolt-platform/src/client/create-worker'

const { fleetConfig } = window.viewerModel
const TB_PERFORMANCE_TOOL_URL = 'https://apps.wix.com/tb-performance-tool'

const addOpenedMessageListenerToWindow = (
	openPerformanceToolWindow: Window | null,
	performanceEntries: Array<PerformanceEntry>
) => {
	window.addEventListener('message', (msg) => {
		if (msg.data === 'opened') {
			// @ts-ignore
			openPerformanceToolWindow!.postMessage(
				{
					type: 'performanceData',
					data: JSON.parse(JSON.stringify(performanceEntries)),
				},
				'*'
			)
		}
	})
}

if (fleetConfig.type === 'Canary' || window.location.search.includes('performanceTool=true')) {
	// @ts-ignore
	window.openPerformanceTool = async () => {
		const { ssrEvents } = JSON.parse(document.getElementById('wix-warmup-data')?.textContent || '{}')
		const workerThread = await platformWorkerPromise
		const openPerformanceToolWindow = window.open(TB_PERFORMANCE_TOOL_URL, '_blank')
		const performanceEntries = window.performance.getEntries()

		if (ssrEvents) {
			performanceEntries.push(
				...ssrEvents.map((entry: { name: string; startTime: number }) => {
					return {
						name: entry.name,
						entryType: 'mark',
						startTime: entry.startTime - window.performance.timeOrigin,
						duration: 0,
					}
				})
			)
		}
		// @ts-ignore
		if (workerThread) {
			// @ts-ignore
			workerThread.addEventListener('message', (event) => {
				if (event.data?.type === 'workerPerformanceData') {
					const workerPerf = event.data.data.performanceEntries
					const workerStartTime = event.data.data.workerStartTime
					const workerZeroBuffer = workerStartTime - window.performance.timeOrigin
					workerPerf.forEach((entry: any) => {
						entry.startTime = entry.startTime + workerZeroBuffer
						entry.worker = 'WORKER'
					})
					performanceEntries.push(...workerPerf)
					addOpenedMessageListenerToWindow(openPerformanceToolWindow, performanceEntries)
				}
			})
			// @ts-ignore
			workerThread.postMessage({ type: 'PerformanceTool' })
		} else {
			addOpenedMessageListenerToWindow(openPerformanceToolWindow, performanceEntries)
		}
	}
}
